import { Component, Input } from '@angular/core';

@Component({
  selector: 'so-sidebar-simple',
  templateUrl: './so-sidebar-simple.component.html',
  styleUrl: './so-sidebar-simple.component.scss'
})
export class SoSidebarSimpleComponent {

  @Input() showVersionApp: boolean = true;
  @Input() customStyle: string = '';
  @Input() showTextApp: boolean = false; 

}
