<div class="container-lateral-menu" [style]="customStyle">
    <section class="logotipo">
      <img src="assets\img\op.png" width="54" />
      <div style="height: 6px;"></div>
      @if(showVersionApp){
        <p> V.10 </p>
      }
    </section>
    @if(showTextApp){
      <div class="vertical-text visible">
        {{ 'ai_configuration' | translated : 'Configuración IA' | uppercase }}
      </div> 
    }
</div>
