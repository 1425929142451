import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

interface filters{
    showFilter:boolean,
    iconButton:string,
    icon:string | null,
    showTitle: boolean
}

const filtersObject: filters = {
    showFilter: false,
    iconButton: '',
    icon: '',
    showTitle: false
}

export const showFilters = signalStore(
    { providedIn: 'root' },
    withState(filtersObject),
    withMethods(( { ...store }) => ({
        changeFilterDisplay(display: filters) {
            patchState(store, display);
        },
    }))
);