import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-so-radio',
  templateUrl: './so-radio.component.html',
  styleUrls: ['./so-radio.component.scss'],
})
export class SoRadioComponent implements OnInit {
  @Input() checked: boolean | number = false;
  @Input() checkLabel: boolean = true;
  @Input() ratioId?: string;
  @Input() nameGroup?: string;
  @Input() text?: string;
  @Input() opacity?: string = '';
  @Input() gap?: string = '1.2rem';
  @Input() labelPosition?: string;
  @Input() fontWeight: string = '500';
  @Input() labelColor: string = '#272D3B';
  @Input() fontSize: string ='14px';
  @Input() isDisabled: boolean = false;

  ngOnInit(): void {
    this.positionLabel();
  }

  validarStatus($event: any) {
    if(!this.isDisabled){
      let check = $event.target.checked;
      this.checked = check;
    }
  }
  positionLabel() {
    if (this.labelPosition === 'right') {
      this.labelPosition = 'flex-row-reverse';
    }
  }
}
